import { Component } from '@angular/core';

@Component({
  selector: 'bc-load-sidebar',
  templateUrl: './load-sidebar.component.html',
  styleUrls: ['./load-sidebar.component.scss']
})
export class LoadSidebarComponent {

  constructor() { }

}
