import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';

import { BcMessagesModule } from '@core/widgets';

import { ContinueComponent } from './continue.component';

const continueRoutes: Routes = [{ path: '', component: ContinueComponent }];

@NgModule({
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    BcMessagesModule,

    DxFormModule,
    DxButtonModule,
    DxTextBoxModule,
    DxValidatorModule,

    RouterModule.forChild(continueRoutes)
  ],
  declarations: [ContinueComponent]
})
export class ContinueModule {}
