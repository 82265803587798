<div class="card bc-aside-panel" *ngIf="(tenant$ | async) as tenant; else loadingBlock;">
  <div class="card-header bg-white flex flex-row">
    <svg class="svg-icon profile-icon" width="40" height="40">
      <use xlink:href="#profileIcon"></use>
    </svg>

    <div class="card-header-detail">
      <h5 *ngIf="tenant">{{ tenant.name }}</h5>
    </div>
  </div>
  <div class="card-body card-body-detail" [ngSwitch]="component">
    <ng-template [ngSwitchCase]="'document-center'">
      <p class="card-text" i18n>
        In the document center you can find all documents related to your products. Personal documents, such as your
        passport, you will find with your personal details. Cannot find what you are looking for? Please contact us.
        We are here to help you further.
      </p>
    </ng-template>
    <ng-template [ngSwitchCase]="'party-details'">
      <p class="card-text" i18n>
        Welcome to your personal information overview. Here you will be able to find your contact details used during
        your registration. Furthermore, there are tabs listing your “Personal details”, “Additional details” and
        “Qualifications and interest”.
      </p>
    </ng-template>
    <ng-template ngSwitchDefault>
      <p class="card-text"></p>
    </ng-template>
  </div>
</div>

<ng-template #loadingBlock>
  <bc-load-sidebar></bc-load-sidebar>
</ng-template>
