<div class="wrap">
  <div class="header">
    <svg class="svg-icon profile-icon" width="40" height="40">
      <use xlink:href="#profileIcon"></use>
    </svg>

    <div class="caption_placeholder placeholder"></div>
  </div>

  <div class="content">
    <div class="placeholder"></div>
    <div class="placeholder"></div>
    <div class="placeholder"></div>
    <div class="placeholder placeholder__short"></div>
  </div>

  <div class="spinner">
    <bc-spinner></bc-spinner>
  </div>
</div>
