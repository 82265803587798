import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StubsModule } from '@shared/components/stubs/stubs.module';

import { AssetManagerCardComponent } from './asset-manager-card.component';

@NgModule({
  imports: [
    CommonModule,
    StubsModule
  ],
  declarations: [AssetManagerCardComponent],
  exports: [AssetManagerCardComponent]
})
export class AssetManagerCardModule {}
