import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipeModule } from '@shared/pipes/pipe.module';
import { SpinnerModule } from '@shared/components/spinner/spinner.module';
import { LoadDetailsStubComponent } from './load-details-stub/load-details-stub.component';
import { LoadSidebarComponent } from './load-sidebar/load-sidebar.component';


@NgModule({
  imports: [
    CommonModule,
    SpinnerModule,
    PipeModule
  ],
  declarations: [
    LoadDetailsStubComponent,
    LoadSidebarComponent,
  ],
  exports: [
    SpinnerModule,
    LoadDetailsStubComponent,
    LoadSidebarComponent
  ]
})
export class StubsModule { }
