import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { UserLoginService } from '@app/shared/services/user-login.service';
import { UsersGatewayService } from '@blancoservices/bc-auth-web';

@Component({
  selector: 'bc-continue',
  template: ''
})
export class ContinueComponent {
  constructor(
    private router: Router,
    private usersGateway: UsersGatewayService,
  ) {
    localStorage.clear();
    const cs = new Map();
    const cookies = document.cookie.split('; ');

    cookies.forEach(cookie => {
      const [key, value] = cookie.split('=');

      cs.set(key, value);
    });

    const accessToken = cs.get('at');
    localStorage.setItem('accessToken', accessToken);
    const token = cs.get('rt');
    this.usersGateway.refreshToken({
      refreshToken: token
    }).toPromise()
      .then((result: any) => {
        UserLoginService.redirectProspects(result.idToken.payload, result.refreshToken.token);
        router.navigate(['/dashboard']);
      })
      .catch(() => router.navigate(['/login']));
  }
}
