// 3rd party libs
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// components
import { HeaderComponent } from './../header/header.component';
import { LayoutComponent } from './layout.component';
import { NotificationsMenuComponent } from '@shared/components/header/notifications-menu/notifications-menu.component';
import { UserMenuComponent } from '@shared/components/header/user-menu/user-menu.component';
import { PipeModule } from '@shared/pipes/pipe.module';
import { LanguageSwitcherModule } from '@shared/components/language-switcher/language-switcher.module';
import { BcDropdownModule } from '@core/widgets/bc.dropdown/bc.dropdown.module';
import { SoftwareMfaModule } from '@core/components/software-mfa/software-mfa.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PipeModule,
    BcDropdownModule,
    LanguageSwitcherModule,
    SoftwareMfaModule
  ],
  declarations: [
    HeaderComponent,
    LayoutComponent,
    NotificationsMenuComponent,
    UserMenuComponent
  ],
  exports: [
    HeaderComponent,
    LayoutComponent,
    NotificationsMenuComponent,
    UserMenuComponent
  ]
})
export class LayoutModule { }
