import { Component, OnInit, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { TenantService } from '@shared/services/data-service/tenant.service';
import { Tenant } from '@shared/models/tenant';

@Component({
  selector: 'bc-asset-manager-card',
  templateUrl: './asset-manager-card.component.html',
  styleUrls: ['./asset-manager-card.component.scss']
})
export class AssetManagerCardComponent implements OnInit {
  @Input() component: string;

  tenant$: Observable<Tenant>;

  constructor(private tenantService: TenantService) {}

  ngOnInit() {
    this.tenant$ = this.tenantService.getTenantDetails();
  }
}
