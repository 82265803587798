import { Inject, Injectable, LOCALE_ID } from '@angular/core';

export enum ValueType {
  TEXT = 'TEXT',
  CURRENCY = 'CURRENCY',
  NUMBER = 'NUMBER',
  PERCENTAGE = 'PERCENTAGE'
}
export const NO_DATA_DISPLAY = '—';

@Injectable({
  providedIn: 'root'
})
export class DisplayService {
  constructor(
    @Inject(LOCALE_ID) private readonly localeId: string,
  ) {}

  /**
   * Get value from object by key (and index, if value is an array) and returns view version of field using type
   * Method will find out type of value but you still can pass type
   */
  getValue(object: any, key: string, type?: ValueType, index?: number): string {
    let result: string = object[key];
    if (result && index !== undefined) result = result[index];
    if (!result) result = NO_DATA_DISPLAY;
    if (!type && !isNaN(+result)) type = ValueType.CURRENCY;

    switch (type) {
      case ValueType.CURRENCY: result = this.parseCurrencyValue(result, this.localeId); break;
      case ValueType.PERCENTAGE: result = this.parsePercentValue(result, this.localeId); break;
      case ValueType.NUMBER: break;
      case ValueType.TEXT: break;
      default: break;
    }

    return result;
  }

  /**
   * Find element in array by value and returns name of found element
   */
  getNameByValueFromArray(array: Array<any>, value: string): string {
    const foundElement = array.find(element => element.value === value);

    return foundElement ? foundElement.name : NO_DATA_DISPLAY;
  }

  /**
   * Find element in array by key and returns name of found element
   */
  getNameByKeyFromArray(array: Array<any>, value: string): string {
    const foundElement = array.find(element => element.key === value);

    return foundElement ? foundElement.name : NO_DATA_DISPLAY;
  }

  parseCurrencyValue(value: any, localeId: string): string {
    return (+value).toLocaleString(localeId, {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    });
  }

  parsePercentValue(value: any, localeId: string): string {
    // eslint-disable-next-line no-magic-numbers
    return (+value / 100).toLocaleString(localeId, {
      style: 'percent',
      maximumSignificantDigits: 10,
      minimumFractionDigits: 0
    });
  }
}
